import links from 'links'


const adminLinks = [
  {
    title: 'АРМ Ситименеджер',
    icon: 'business/statistics_16',
    subNav: [
      {
        to: links.admin.arm.root,
        icon: 'business/dashboard_16',
        accessRoles: [ 'ADMIN', 'OPERATOR', 'MODERATOR' ],
      },
      {
        to: links.admin.constructions.root,
        icon: 'file/insert_16',
        accessRoles: [ 'ADMIN', 'OPERATOR', 'MODERATOR' ],
      },
      {
        to: links.admin.requests.root,
        icon: 'business/progress_16',
        accessRoles: [ 'ADMIN', 'OPERATOR', 'MODERATOR' ],
      },
    ],
  },
  {
    title: 'ИАС СЦ',
    icon: 'business/progress_16',
    tag: 'RCC',
    accessRoles: [ 'ADMIN', 'OPERATOR', 'MODERATOR' ],
    subNav: [],
  },
  {
    to: links.admin.asudd.root,
    icon: 'transport/traffic-lights_16',
    accessRoles: [ 'ADMIN', 'OPERATOR', 'MODERATOR' ],
  },
  {
    title: 'Активный житель',
    icon: 'work/active-citizen_16',
    subNav: [
      {
        to: links.admin.appeals.root,
        icon: 'government/new_16',
        accessRoles: [ 'ADMIN', 'OPERATOR', 'MODERATOR' ],
      },
      {
        toTab: links.manager.votes.root,
        icon: 'communication/message_16',
        accessRoles: [ 'ADMIN', 'OPERATOR', 'MODERATOR' ],
      },
    ],
  },
  {
    icon: 'text/align-left_16',
    toTab: links.manager.news.root,
    accessRoles: [ 'ADMIN', 'OPERATOR', 'MODERATOR' ],
  },
  {
    toTab: links.manager.afisha.root,
    icon: 'time/calendar_16',
    accessRoles: [ 'ADMIN', 'OPERATOR', 'MODERATOR' ],
  },
  {
    title: 'Службы города',
    icon: 'cadastral/mncpl-control_16',
    subNav: [
      {
        to: links.admin.institutions.root,
        icon: 'cadastral/mncpl-control_16',
        accessRoles: [ 'ADMIN' ],
      },
      {
        to: links.admin.medicine,
        icon: 'government/medicine_16',
        accessRoles: [ 'ADMIN' ],
      },
      {
        to: links.admin.police.root,
        icon: 'government/police_16',
        accessRoles: [ 'ADMIN' ],
      },
      {
        to: links.admin.accessibility.root,
        icon: 'work/invalid_16',
        accessRoles: [ 'ADMIN', 'OPERATOR', 'MODERATOR' ],
      },
    ],
  },
  {
    title: 'Городская инфраструктура',
    icon: 'main/avatar_16',
    subNav: [
      {
        to: links.admin.gisZkh,
        icon: 'industry/cold-water_16',
        accessRoles: [ 'ADMIN', 'OPERATOR', 'MODERATOR' ],
      },
      {
        to: links.admin.serviceArea,
        icon: 'map/layers_16',
        accessRoles: [ 'ADMIN' ],
      },
      {
        to: links.admin.controlTKO.root,
        icon: 'ecology/tko_16',
        accessRoles: [ 'ADMIN' ],
      },
      {
        to: links.admin.cleaning.root,
        icon: 'government/city-clean_16',
        accessRoles: [ 'ADMIN' ],
      },
      {
        to: links.admin.excavation.root,
        icon: 'government/construction_16',
        accessRoles: [ 'ADMIN', 'MODERATOR' ],
      },
      {
        to: links.admin.communications.root,
        icon: 'industry/gas-supply_16',
        accessRoles: [ 'ADMIN', 'MODERATOR' ],
      },
      {
        to: links.admin.lighting.root,
        icon: 'other/etc_16',
        accessRoles: [ 'ADMIN', 'OPERATOR', 'MODERATOR' ],
      },
      {
        to: links.admin.videoControl.root,
        icon: 'IT/camera-cctv_16',
        accessRoles: [ 'ADMIN', 'OPERATOR', 'MODERATOR' ],
      },
      {
        to: links.admin.yards.root,
        icon: 'places/park_16',
        accessRoles: [ 'ADMIN', 'OPERATOR', 'MODERATOR' ],
      },
      {
        to: links.admin.elevators.root,
        icon: 'industry/elevator_16',
        accessRoles: [ 'ADMIN', 'OPERATOR', 'MODERATOR' ],
      },
      {
        to: links.admin.warningPoints.root,
        icon: 'main/avatar_16',
        accessRoles: [ 'ADMIN', 'OPERATOR', 'MODERATOR' ],
      },
      {
        to: links.admin.mobileStations.root,
        icon: 'communication/telephone_16',
        accessRoles: [ 'ADMIN', 'OPERATOR', 'MODERATOR' ],
      },
      {
        to: links.admin.kumi.root,
        icon: 'cadastral/blueprint_16',
        accessRoles: [ 'ADMIN', 'OPERATOR', 'MODERATOR' ],
      },
      {
        to: links.admin.heating.root,
        icon: 'industry/heat_16',
        accessRoles: [ 'ADMIN', 'OPERATOR', 'MODERATOR' ],
      },
      {
        to: links.admin.alerts.root,
        icon: 'communication/telephone_16',
        accessRoles: [ 'ADMIN', 'OPERATOR', 'MODERATOR' ],
      },
      {
        to: links.admin.transport.root,
        icon: 'transport/bus_16',
        accessRoles: [ 'ADMIN' ],
      },
      {
        to: links.admin.serviceTransport.root,
        icon: 'government/emergency_16',
        accessRoles: [ 'ADMIN', 'MODERATOR', 'OPERATOR' ],
      },
    ],
  },
  {
    to: links.admin.elections.root,
    icon: 'government/government_16',
    accessRoles: [ 'ADMIN' ],
  },
  {
    to: links.admin.culture.root,
    icon: 'government/culture_16',
    accessRoles: [ 'ADMIN' ],
    tag: 'CULTURE_OBJECTS',
  },
  {
    title: 'Умные устройства',
    icon: 'industry/dash_16',
    subNav: [
      {
        to: links.admin.scud,
        icon: 'action/lock_16',
        accessRoles: [ 'ADMIN', 'OPERATOR', 'MODERATOR' ],
      },
      {
        to: links.admin.counters.root,
        icon: 'industry/dash_16',
        accessRoles: [ 'ADMIN', 'OPERATOR', 'MODERATOR' ],
      },
      {
        to: links.admin.electric.root,
        icon: 'communication/telephone_16',
        accessRoles: [ 'ADMIN', 'OPERATOR', 'MODERATOR' ],
      },
    ],
  },
  {
    toTab: 'https://tourism.vd.rusatom.dev/admin',
    title: 'Городские экраны',
    icon: 'work/team-people_16',
    tag: 'CITY_SCRNS',
    accessRoles: [ 'ADMIN', 'OPERATOR', 'MODERATOR' ],
  },
  {
    title: 'Проекты',
    icon: 'work/active-citizen_16',
    subNav: [
      {
        to: links.admin.immortals.root,
        icon: 'work/team-people_16',
        accessRoles: [ 'ADMIN' ],
        tag: 'IMMORTALS',
      },
      {
        toTab: links.manager.projectGv.root,
        icon: 'work/active-citizen_16',
        tag: 'PROJECT_GV',
        accessRoles: [ 'ADMIN', 'OPERATOR', 'MODERATOR' ],
      },
      {
        toTab: links.manager.projectIb.root,
        icon: 'work/active-citizen_16',
        tag: 'PROJECT_IB',
        accessRoles: [ 'ADMIN', 'OPERATOR', 'MODERATOR' ],
      },
      {
        toTab: links.manager.projectSz.root,
        icon: 'work/active-citizen_16',
        tag: 'PROJECT_SZ',
        accessRoles: [ 'ADMIN', 'OPERATOR', 'MODERATOR' ],
      },
      {
        toTab: links.manager.projectBld.root,
        icon: 'work/active-citizen_16',
        tag: 'PROJECT_BLD',
        // count: counts['cityProjects-bld'],
        accessRoles: [ 'ADMIN', 'OPERATOR', 'MODERATOR' ],
      },
    ],
  },
  {
    title: 'Бизнес',
    icon: 'business/business-case_16',
    subNav: [
      {
        to: links.admin.rent.root,
        icon: 'retail/payment-hcs_16',
        accessRoles: [ 'ADMIN', 'MODERATOR' ],
      },
      {
        to: links.admin.nto.root,
        icon: 'places/park_16',
        accessRoles: [ 'ADMIN', 'MODERATOR' ],
      },
    ],
  },
  {
    title: 'Платные услуги',
    icon: 'retail/payment-wallet_16',
    subNav: [
      {
        to: links.admin.sport.root,
        icon: 'government/sport_16',
        accessRoles: [ 'ADMIN', 'MODERATOR', 'OPERATOR' ],
      },
      {
        to: links.admin.edu.root,
        icon: 'other/hot-meal_16',
        accessRoles: [ 'ADMIN', 'MODERATOR', 'OPERATOR' ],
      },
      {
        toTab: links.manager.advertising.root,
        icon: 'work/active-citizen_16',
        tag: 'ADVERTISING',
        accessRoles: [ 'ADMIN', 'OPERATOR', 'MODERATOR' ],
      },
    ],
  },
  {
    title: 'Безопасность',
    icon: 'government/security_16',
    subNav: [
      {
        to: links.admin.hydrants.root,
        icon: 'industry/hydrant_16',
        accessRoles: [ 'ADMIN', 'MODERATOR' ],
      },
      {
        to: links.admin.roadEvents.root,
        icon: 'transport/bus_16',
        accessRoles: [ 'ADMIN', 'OPERATOR', 'MODERATOR' ],
      },
      {
        to: links.admin.covid.root,
        icon: 'government/medicine_16',
        accessRoles: [ 'ADMIN' ],
      },
    ],
  },
  // Should be at the bottom of the list
  {
    to: links.admin.users.root,
    title: 'Пользователи',
    icon: 'main/avatar_16',
    accessRoles: [ 'ADMIN', 'OPERATOR' ],
  },
  {
    to: links.admin.settings.root,
    icon: 'main/settings_16',
    accessRoles: [ 'ADMIN', 'MODERATOR' ],
  },
]


export default adminLinks
