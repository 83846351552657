import React from 'react'
import { Field, useFieldState, useFormState } from 'formular'

import { Text } from 'components/dataDisplay'
import { Input, Button } from 'components/inputs'


type RegistrationProps = {
  className?: string
  newNameField: Field<string>
  newEmailField: Field<string>
  newPhoneField: Field<string>
  newPasswordField: Field<string>
  newPasswordRepeatField: Field<string>
  handleRegistrationSubmit: () => void
}

const Registration: React.FC<RegistrationProps> = (props) => {
  const { className, newNameField, newEmailField, newPasswordField, newPasswordRepeatField,
    newPhoneField, handleRegistrationSubmit,
  } = props

  const { isValid: isNameValid, isChanged: isNameChanged } = useFieldState(newNameField)
  const { isValid: isEmailValid, isChanged: isEmailChanged } = useFieldState(newEmailField)
  const { isValid: isPasswordValid, isChanged: isPasswordChanged } = useFieldState(newPasswordField)
  const { isValid: isPhoneValid } = useFieldState(newPhoneField)
  const { isValid: isPasswordRepeatValid, isChanged: isPasswordRepeatChanged } = useFieldState(newPasswordRepeatField)
  const isValid = isEmailValid && isPasswordValid && isPasswordRepeatValid && isNameValid && isPhoneValid
  const isChanged = isNameChanged && isEmailChanged && isPasswordChanged && isPasswordRepeatChanged

  return (
    <>
      <Text
        message="Введите свои данные"
        size="h24"
      />
      <form id="registration">
        <Input
          className="mt-24px"
          field={newNameField}
          label="Имя"
          icon="main/avatar_16"
          iconPosition="left"
          autoComplete="none"
        />
        <Input
          className="mt-24px"
          field={newEmailField}
          label="Email"
          icon="communication/mail_16"
          iconPosition="left"
          autoComplete="none"
        />
        <Input
          className="mt-24px"
          field={newPhoneField}
          label="Телефон"
          mask="phone"
          icon="communication/telephone_16"
          iconPosition="left"
          autoComplete="none"
        />
        <Input
          className="mt-24px"
          field={newPasswordField}
          label="Пароль"
          icon="action/lock_16"
          iconPosition="left"
          type="password"
          autoComplete="new-password"
        />
        <Input
          className="mt-24px"
          field={newPasswordRepeatField}
          label="Пароль повторно"
          icon="action/lock_16"
          iconPosition="left"
          type="password"
          autoComplete="new-password"
        />
        <Button
          className="mt-24px"
          title="Продолжить"
          style="primary"
          size={40}
          fullWidth
          type="submit"
          onClick={handleRegistrationSubmit}
          disabled={!isChanged || !isValid}
        />
      </form>
    </>
  )
}

export default Registration