import React from 'react'
import cx from 'classnames'
import { Menu } from '@headlessui/react'

import { Href } from 'components/navigation'
import { Text } from 'components/dataDisplay'

import s from './ActionList.scss'


export type ActionListProps = {
  className?: string
  isPage?: boolean
  listItems: {
    className?: string
    title: string
    text: string
    date: string
    to?: string
    href?: string
    disabled?: boolean
    onClick?: () => void
  }[]
}

const ActionList: React.FC<ActionListProps> = (props) => {
  const { className, listItems, isPage=false } = props

  const maxHeight = isPage === false ? '375px' : '600px'

  return (
    <div className={cx(className, s.containerWrapper)} style={{ overflowY: 'auto', overflowX: 'hidden', maxHeight: maxHeight }}>
      {
        listItems.map((listItem, index) => {
          const { className, title, text, date, to, href, disabled, onClick } = listItem
          const itemClassName = cx(className, {
            'opacity-24': disabled,
            'mt-20px': index,
          })

          const contentNode = (
            <>
              <Text
                className="opacity-32"
                message={date}
                size={isPage ? 's13-r' : 's10' }
                color="titanic"
              />
              <Text
                className="mt-4px"
                message={title}
                size={ isPage ? 'c20' : 'c16' }
                color="rocky"
              />
              <Text
                size={ isPage ? 't16-20' : 's13-r' }
                color="titanic"
              >
                <span dangerouslySetInnerHTML={{ __html: text }} />
              </Text>
            </>
          )

          if (to || href) {
            return (
              <Menu.Item
                key={index}
                className={cx(s.itemLink, itemClassName, 'block')}
                as={href ? 'a' : Href}
                to={to}
                href={href}
                onClick={onClick}
              >
                {contentNode}
              </Menu.Item>
            )
          }

          return (
            <div
              key={index}
              className={itemClassName}
            >
              {contentNode}
            </div>
          )
        })
      }
    </div>
  )
}


export default React.memo(ActionList)
