import config from 'config'
import { useMemo } from 'react'
import links, { getLinkWithParams, routeData } from 'links'

import { getModuleTitle } from 'models/settings'
import { usePublicModules, usePurchasedModules } from 'models/city'


const navItems: Header.NavigationItem[] = [
  { title: 'Новости', to: links.news.root },
  { title: 'Календарь событий', to: links.events.root },
  {
    title: 'Активный житель',
    subItems: [
      { title: 'Городские проблемы', to: links.appeals.root },
      { title: 'Опросы', to: links.votings.root },
      { title: 'Инициативы', to: links.initiatives },
      { title: 'Бюджет района для граждан', toTab: 'http://budget.dfik.ru', tag: 'CITY_BUDGET' },
    ],
  },
  { title: 'Транспорт', to: links.transport.root },
  {
    title: 'Сервисы',
    subItems: [
      { title: 'Участковые пункты', to: links.police.root, sectionId: 0 },
      { title: 'Избирательные округа', to: links.elections.root, sectionId: 0 },
      { title: 'Учреждения социальной сферы', to: links.institutions.root, sectionId: 0 },
      { title: 'Медицина', to: links.medicine.root, sectionId: 0 },
      { title: 'Достопримечательности', to: links.culture.root, sectionId: 0 },
      { title: 'Уборка города', to: links.cleaning, sectionId: 0 },
      { title: 'Точки сбора ТКО', to: links.controlTKO, sectionId: 0 },
      { title: 'Территории обслуживания УК', to: links.serviceArea.root, sectionId: 0 },
      { title: 'Обеспечение мобильности', to: links.accessibility.root, sectionId: 0 },
      { title: 'Оперативные оповещения', to: links.alerts.root, sectionId: 0 },
      { title: 'Земляные работы', to: links.excavation.root, sectionId: 0 },
      { title: 'Питание учащихся', to: links.edu.root, sectionId: 1 },
      { title: 'Услуги учреждений', to: links.sport.root, sectionId: 1 },
      { title: 'Товары и услуги', to: links.goodsAndServices.root, sectionId: 1 },
      { title: 'ДТП', to: links.roadEvents.root, sectionId: 1 },
    ],
    sections: [
      {
        id: 0,
        title: 'Информационные',
      },
      {
        id: 1,
        title: 'Платные услуги',
      },
    ],
  },
  {
    title: 'Бизнесу',
    subItems: [
      { title: 'Площади в аренду', to: links.rent.root },
      { title: 'Нестационарные ТО', to: links.nto },
    ],
  },
  {
    title: 'Проекты',
    subItems: [
      { title: 'Бессмертный полк', to: links.immortals.root },
      { title: 'Проекты развития города', to: links.projects.planGv },
      { title: 'Инициативные проекты', to: links.projects.planIb },
      { title: 'Планы по благоустройству', to: links.projects.planSz },
    ],
  },
]
  .reduce((result, item) => {
    if (config.env === 'prod' || true) {
      if (item.subItems) {
        const subItems = item.subItems.filter(({ to }) => to !== '/')

        if (subItems.length) {
          const subItemsIndexes = item.subItems.map(({ to, sectionId }) => to === '/' ? sectionId : null)
          const sections = item.sections?.reduce((result, { id, title }) => {
            const filteredItems = subItems.filter((index) => !subItemsIndexes.includes(index))

            if (filteredItems.length) {
              result.push({
                id,
                title,
              })
            }

            return result
          }, [])

          result.push({
            ...item,
            subItems,
            sections,
          })
        }
      }
      else if (item.to !== '/') {
        result.push(item)
      }
    }
    else {
      result.push(item)
    }

    return result
  }, [])

const hasAccess = (to: string, tags: string[]) => {
  const tag = routeData[to]?.tag

  return tags.includes(tag)
}

const useNavigation = () => {
  const { purchasedModuleTags } = usePurchasedModules()
  const { modules, isFetching } = usePublicModules()

  const items = useMemo(() => {
    if (purchasedModuleTags.length === 1) {
      return []
    }

    return navItems
      .map((navItem) => {
        if (navItem.subItems) {
          return {
            ...navItem,
            subItems: navItem.subItems.filter(({ to, tag }) => (
              tag ? purchasedModuleTags.includes(tag) : hasAccess(to, purchasedModuleTags)
            )),
          }
        }

        return navItem
      })
      .filter(({ to, tag, subItems }) => {
        if (tag) {
          return purchasedModuleTags.includes(tag)
        }
        else if (to) {
          return hasAccess(to, purchasedModuleTags)
        }

        return Boolean(subItems.length)
      })
      .map((item) => {
        const { to, subItems } = item

        if (to) {
          const tag = routeData[to]?.tag

          return tag ? { ...item, title: getModuleTitle(modules, tag) } : item
        }
        else {
          return {
            ...item,
            subItems: subItems.map((subItem) => ({
              ...subItem,
              title: getModuleTitle(modules, subItem.tag ? subItem.tag : routeData[subItem.to]?.tag),
            })),
          }
        }
      })
  }, [ purchasedModuleTags, modules ])

  return {
    items,
  }
}


export default useNavigation
