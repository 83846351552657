import React, { useMemo } from 'react'
import { routeData } from 'links'
import { useDevice } from 'device'

import { usePurchasedModules } from 'models/city'

import { Carousel } from 'components/dataDisplay'
import Service from 'compositions/ServicesSlider/components/Service/Service'

import s from './ServicesSlider.scss'
import useServicesSlider from './util/useServicesSlider'


const hasAccess = (to: string, tags: string[]) => {
  const tag = routeData[to]?.tag

  return tags.includes(tag)
}

const ServicesSlider: React.FC = () => {
  const { isMobile, isDesktop } = useDevice()

  const { purchasedModuleTags } = usePurchasedModules()

  const { services } = useServicesSlider()

  const filteredServices = useMemo(() => (
    services.filter(({ to }) => hasAccess(to, purchasedModuleTags))
  ), [ purchasedModuleTags, services ])

  return (
    <Carousel
      className={s.carousel}
      itemWidth={isMobile ? 112 + 8 : 136 + 8}
      slidesToShow="auto"
      exactWidth
      scrollLock={false}
      draggable={!isDesktop}
    >
      {
        filteredServices
          .map(({ title, to, icon }, index) => (
            <Service
              key={index}
              icon={icon}
              to={to}
              title={title}
            />
          ))
      }
    </Carousel>
  )
}


export default ServicesSlider
